:root {
  color-scheme: dark;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 11pt;
  line-height: 1.5;

  background-color: #19232E;
  color: #fff;
}

#root {
  height: 100%;
}

.outer {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.sidebar {
  background-color: #222F3E;
  overflow: auto;
  width: 360px;
  flex-grow: 1;
  border-right: 1px rgba(255, 255, 255, 0.15) solid;
  position: relative;
}

.main {
  flex-grow: 100;
}

.sidebaritem {
  padding: 0.75em;
  border-bottom: 1px rgba(255, 255, 255, 0.15) solid;
  user-select: none;
  font-size: 10pt;
  position: relative;
}

.sidebarmessage {
  padding: 0.75em;
  user-select: none;
  font-size: 10pt;
  text-align: center;
  font-style: italic;
}

.dirTree {
  padding: 0.75em;
  font-size: 9pt;
}

.treeEntry {
  font-weight: bold;
  user-select: none;
}

.treeEntry:hover {
  text-decoration: underline;
}

.sidebaritem:hover {
  background: #0060ce;
}

.sidebaritem[data-selected=true] {
  background: #0054b4;
}

.sidebaritemname {
  font-weight: bold;
}

.sidebaritemname svg {
  height: 0.75em;
}

.sidebaritemdate {
  font-size: 9pt;
}

.sidebartoolbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sidebartoolbar button {
  font-size: 10pt;
}

.sidebaritemtrash {
  border-radius: 100%;
  height: 2.5em;
  width: 2.5em;
  position: absolute;
  top: calc(50% - 1.25em);
  right: 0.5em;

/*  display: flex;*/
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sidebaritemtrash svg {
  height: 1em;
}

.sidebaritemtrash:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.sidebarbottombar {
  display: none;
  position: fixed;
  bottom: 1em;
}

.sidebartrashoverlay {
  height: 5em;
  width: 5em;
  text-align: center;
  background-color: #2f4055;
  margin: auto;
  border-radius: 100%;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sidebartrashoverlay svg {
  height: 3em;
}

.sidebartrashoverlayhighlight {
  background-color: #0060ce;
}

button {
  user-select: none;
  margin: 0.5em;
  padding: 0em 1em;
  background: #2f4055;
  text-align: center;
  border-radius: 0.5em;
  font-size: inherit;
  line-height: 2.25;
  display: inline-block;
  border: none;
  font-family: inherit;
  color: inherit;
}

/* exclude .tox-tbtn since it conflicts with tinymce  */
button:not(.tox-tbtn) svg {
  vertical-align: middle;
  height: 1em;
}

button:hover {
  background: #0060ce;
}

button:active {
  background: #0054b4;
}

input[type=text], input[type=email], input[type=password] {
  border: none;
  border-bottom: 2px rgba(255, 255, 255, 0.15) solid;
  background: inherit;
  padding: 0.25em;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
}

input[type=text]:hover, input[type=email]:hover, input[type=password]:hover {
  border-bottom: 2px #ffffff solid;
}

.main {
  padding: 1em;
}

.notearea {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.noteheader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notetitle {
  font-weight: bold;
  font-size: 18pt;
  flex-grow: 1;
}

.notedetails {
}

.noteclose {
  padding: 0.2em 0.55em;
  user-select: none;
}

.noteclose:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.noteclose:active {
  background-color: rgba(255, 255, 255, 0.1);
}

.dragHighlight {
  background-color: #0060ce;
}

.menu {
  border: 2px rgba(255, 255, 255, 0.15) solid;
  position: absolute;
  user-select: none;
}

.menuItem {
  padding: 0.5em;
}

.homePage {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em 2em 5em 2em;
  box-sizing: border-box;
}

.homePage svg {
  max-height: 200px;
}