.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loginPage {
  background-color: #222F3E;
  border-radius: 1em;
  padding: 0.5em;
  font-size: 12pt;
}

.loginTitle {
  text-align: center;
  font-size: 14pt;
  font-weight: bold;
  margin: 0.5em;
}

.loginTable {
  display: table;
  margin: 0.5em 0.5em;
}

.loginRow {
  display: table-row;
}

.loginCell {
  display: table-cell;
  padding: 0.25em;
  vertical-align: middle;
}

.loginButtons {
  text-align: center;
}

.loginError {
  font-size: 9pt;
  text-align: center;
  color: #ff8080;
}

.loginCaptcha {
  text-align: center;
}

.loginCaptcha div {
  display: inline-block;
}
